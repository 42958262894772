<template>
  <div class="block">
    <!-- 日期選擇器 -->
    <el-date-picker
      v-model="date"
      type="datetimerange"
      range-separator="-"
      :start-placeholder="t('common.startDate')"
      :end-placeholder="t('common.endDate')"
      format="YYYY-MM-DD HH:mm:ss"
      date-format="YYYY/MM/DD ddd"
      time-format="HH:mm:ss"
      value-format="x"
      :shortcuts="shortcuts"
      clear-icon="null"
      :clearable="false"
      @change="searchDateFn()"
    />
  </div>
</template>

<script setup>
const { t } = useI18n()

const props = defineProps({
  defaultStartTime: {
    type: [Number, String],
    default: 0
  },
  defaultEndTime: {
    type: [Number, String],
    default: 0
  }
})

// 監聽defaultStartTime, defaultEndTime
watch(
  () => [props.defaultStartTime, props.defaultEndTime], ([newStartTime, newEndTime]) => {
    date.value = [newStartTime, newEndTime]
  }
)

const emit = defineEmits(['searchDate'])

const date = ref([props.defaultStartTime, props.defaultEndTime])

// 快捷選項
const shortcuts = [
  {
    text: t('common.day'), // 當天
    value: () => {
      const start = new Date()
      start.setHours(0, 0, 0, 0)
      const end = new Date()
      end.setHours(23, 59, 59, 999)
      return [start, end]
    }
  },
  {
    text: t('common.yesterday'), // 昨天
    value: () => {
      const start = new Date()
      start.setDate(start.getDate() - 1)
      start.setHours(0, 0, 0, 0)
      const end = new Date()
      end.setDate(end.getDate() - 1)
      end.setHours(23, 59, 59, 999)
      return [start, end]
    }
  },
  {
    text: t('common.week'), // 當周
    value: () => {
      const end = new Date()
      end.setHours(23, 59, 59, 999)

      const start = new Date()
      const dayOfWeek = start.getDay()
      const diffToStartOfWeek = dayOfWeek === 0 ? 6 : dayOfWeek - 1
      start.setDate(start.getDate() - diffToStartOfWeek)
      start.setHours(0, 0, 0, 0)
      return [start, end]
    }
  },
  {
    text: t('common.month'), // 當月
    value: () => {
      const end = new Date()
      end.setHours(23, 59, 59, 999)
      
      const start = new Date()
      start.setDate(1)
      start.setHours(0, 0, 0, 0)
      return [start, end]
    }
  }
]

const searchDateFn = () => {
  emit('searchDate', {
    startTime: date.value[0],
    endTime: date.value[1]
  })
}
</script>

<style lang="scss" scoped>
</style>
